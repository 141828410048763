/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from "react"
import { graphql } from "gatsby"
import { useMove } from "hooks/useMove"

import Layout from "components/Layout"
import BackgroundGradient from "components/BackgroundGradient"
import HeroStars from "components/HeroStars"

import {
  Hero,
  Rules,
  Cities,
  Awards,
  LastYear,
  Faq,
  Gallery,
} from "page_components/home"

const Home = ({ data }) => {
  const lang = "en"
  const { x, y, handleMouseMove } = useMove()

  return (
    <Layout
      seo={{
        title: "Strona główna",
      }}
      lang={lang}
      isHome
    >
      <div>
        <Hero lang={lang} />
        <HeroStars />
        <div
          onMouseMove={handleMouseMove}
          className="background-gradient__wrapper"
        >
          <BackgroundGradient x={x} y={y} />
          <Rules lang={lang} />
          <Cities lang={lang} />
          <Awards lang={lang} />
          <Gallery data={data?.allWpGallery?.nodes} lang={lang} />
        </div>
        <LastYear lang={lang} />
        <div
          onMouseMove={handleMouseMove}
          className="background-gradient__wrapper"
        >
          <BackgroundGradient x={x} y={y} />
          <Faq lang={lang} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpGallery(sort: { order: DESC, fields: date }) {
      nodes {
        id
        acfGallery {
          city
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Home
